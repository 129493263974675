import React, { Component } from 'react';
import { LightgalleryProvider } from 'react-lightgallery';
import ProjectsPhotoItem from './ProjectsPhotoItem';

class ProjectPhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      PROJECT_IMAGES: [],
      isLoaded: false
    };
  }

  componentDidMount() {
    const group = this.props.id;
    let images = [];

    if (group === 'project1') {
      images = [
        'project1-01',
        'project1-02',
        'project1-03',
        'project1-04',
        'project1-05',
        'project1-06',
        'project1-07',
        'project1-08',
        'project1-09'
      ];
    } else if (group === 'project2') {
      images = [
        'project2-01',
        'project2-02',
        'project2-03',
        'project2-04',
        'project2-05',
        'project2-06',
        'project2-06',
        'project2-07',
        'project2-08',
        'project2-09',
        'project2-10'
      ];
    } else if (group === 'project3') {
      images = [
        'project3-01',
        'project3-02',
        'project3-03',
        'project3-04',
        'project3-05',
        'project3-06',
        'project3-07',
        'project3-08'
      ];
    }

    this.setState({
      id: group,
      PROJECT_IMAGES: images,
      isLoaded: true
    });
  }

  render() {
    const { PROJECT_IMAGES, isLoaded, id } = this.state;

    return (
      <>
        <LightgalleryProvider>
          <div className="row row-5">
            {PROJECT_IMAGES.map((p, idx) => (
              <ProjectsPhotoItem key={idx} image={p} group={id} />
            ))}
          </div>
        </LightgalleryProvider>
      </>
    );
  }
}

export default ProjectPhotoGallery;
