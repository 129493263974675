import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class ProductSlider extends Component {
  render() {
    const params = {
      slidesPerView: 3,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      spaceBetween: 30,

      autoplay: {
        delay: 3000
      },
      // Responsive breakpoints
      breakpoints: {
        1499: {
          slidesPerView: 5
        },

        991: {
          slidesPerView: 4
        },
        767: {
          slidesPerView: 2
        },
        575: {
          slidesPerView: 1
        },
        320: {
          slidesPerView: 1
        }
      }
    };
    let data = [
      {
        img: 'project-1.png',
        // iconName: 'flaticon-002-welding',
        serviceTitle: 'Baterias',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-2.png',
        // iconName: 'flaticon-004-walkie-talkie',
        serviceTitle: 'Silos metálicos',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-3.png',
        // iconName: 'flaticon-015-cart',
        serviceTitle: 'Zonas de Lixagem',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-4.png',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Cabines',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-5.png',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Ventiladores',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-6.png',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Indústria do calçado',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-7.png',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Metalomecânica',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-8.png',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Controlo de temperatura',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'project-9.png',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Caldeiras',
        serviceUrl: 'service-details-left-sidebar'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide" key={i}>
          <div className="service-grid-item">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <img
                  src={`assets/img/projects/${val.img}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
              {/* <div className="icon">
                <i className={val.iconName} />
              </div> */}
            </div>
            <div className="service-grid-item__content">
              <h3 className="title" style={{ textAlign: 'center' }}>
                {/* <a href={`${process.env.PUBLIC_URL}/${val .serviceUrl}`}> */}
                <a>{val.serviceTitle}</a>
              </h3>
              {/* <p className="subtitle">{val.serviceExcerpt}</p> */}
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  service grid slider area ====================*/}
        <div className="service-grid-slider-area section-space--inner--120">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <h2 className="section-title section-space--bottom--50">
                    Os Nossos Produtos
                    {/* <span className="title-icon" /> */}
                  </h2>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-slider">
                  <Swiper {...params}>{DataList}</Swiper>
                </div>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div>
                <a href="/equipments" class="ht-btn ht-btn--round">
                  TODOS OS EQUIPAMENTOS
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of service grid slider area  ====================*/}
      </div>
    );
  }
}

export default ProductSlider;
