import React, { Component } from 'react';

export default class ProjectDetailsTitle extends Component {
  render() {
    const { group } = this.props;
    console.log(this.props);

    return (
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>{group}</h1>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/equipments">Equipamentos</a>
                  </li>
                  <li>{group}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
