import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ProjectPhotoGallery from './components/ProjectPhotoGallery';
import ProjectDetailsTitle from './components/ProjectDetailsTitle';
import ProjectTitle from './components/ProjectTitle';

class FullProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '',
      data: {},
      isLoaded: false
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const tab = params.detail;
    let data = [{}];

    console.log('TABBBB', tab);

    if (tab === 'project-1') {
      data = {
        group: 'project',
        srcFolder: 'project1',
        ano: '2020',
        setor: 'Mobiliário, Madeira',
        location: 'Açores, Portugal',
        description:
          'Com vários projetos e clientes satisfeitos, a marca Ventasel é já uma referência também nas ilhas!',
        projectImg: '/assets/img/fullProjects/project1/project1.jpg',
        homeImage: 'fullProjects/project-01/project1.jpg',
        title: 'Projetos nos Açores'
      };
    } else if (tab === 'project-2') {
      data = {
        group: 'project',
        srcFolder: 'project2',
        ano: '2021',
        description:
          'Projeto de sistema de aspiração de resíduos de madeira. Composto por um ventilador de 60cv, bateria de mangas, silo com extração e alimentação automática à caldeira.',
        description2:
          'Zonas de acabamento composta por uma cabine pressurizada, aquecida e húmidificada, com cortina de àgua e zona de lixagem de 10 metros',
        setor: 'Mobiliário, Madeira',
        location: 'Sinta, Portugal',
        projectImg: '/assets/img/fullProjects/project2/project2.jpg',
        homeImage: 'fullProjects/project-01/project1.jpg',
        title: 'Sistema de despoeiramento completo'
      };
    } else if (tab === 'project-3') {
      data = {
        group: 'project',
        srcFolder: 'project3',
        ano: '2019',
        description:
          'Projeto de sistema de aspiração de resíduos de madeira. Composto por um ventilador de 60cv, bateria de mangas, silo com extração e alimentação automática à caldeira.',
        description2:
          'Zonas de acabamento composta por uma cabine pressurizada, aquecida e húmidificada, com cortina de àgua e zona de lixagem de 10 metros',
        setor: 'Mobiliário, Madeira',
        location: 'Oliveira do Hospital, Portugal',
        projectImg: '/assets/img/fullProjects/project3/project3.jpg',
        homeImage: 'fullProjects/project-03/project3.jpg',
        title: 'Sistema de despoeiramento completo'
      };
    }

    this.setState({
      tab: tab,
      isLoaded: true,
      data: data
    });
  }

  // componentDidUpdate(prevProps) {
  //     console.log(this.state)
  //     console.log(prevProps)
  //     // Typical usage (don't forget to compare props):
  //     // if (this.props.userID !== prevProps.userID) {
  //     //   this.fetchData(this.props.userID);
  //     // }
  //   }

  render() {
    const {
      group,
      ano,
      description,
      description2,
      setor,
      location,
      srcFolder,
      homeImage,
      title,
      text,
      projectImg,
      subText
    } = this.state.data;
    const { isLoaded, tab } = this.state;

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/*====================  breadcrumb area ====================*/}
        {isLoaded ? (
          <ProjectTitle
            title={title}
            text={text}
            group={title}
            homeImage={homeImage}
          ></ProjectTitle>
        ) : null}

        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12 section-space--bottom--40">
                  <div className="project-image">
                    <img src={projectImg} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <ul>
                      <li>
                        <strong>Localização:</strong> {location}
                      </li>

                      <li>
                        <strong>Ano:</strong> {ano}
                      </li>
                      <li>
                        <strong>Setor:</strong> <a>{setor}</a>{' '}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>{title}</h2>
                    <p>{description}</p> <p>{description2}</p>
                  </div>
                </div>
                <div className="col-12">
                  {this.state.isLoaded ? (
                    <ProjectPhotoGallery id={srcFolder} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>
        {/*Projects section end*/}

        <BrandLogoSlider background="grey-bg" />

        <Footer />

        <MobileMenu />
      </div>
    );
  }
}

export default FullProjects;
