import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./index.scss";
import HomePage from "./HomePage";
import Equipments from "./project/Equipments";
import FullProjects from "./project/FullProjects";
import Projects from "./project/Projects";
import ProjectDetails from "./project/ProjectDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";


class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={HomePage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/equipments`}
            component={Equipments}
          />         
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projects`}
            component={Projects}
          /> <Route
            exact
            path={`${process.env.PUBLIC_URL}/full-projects/:detail`}
            component={FullProjects}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/project-details/:detail`}
            component={ProjectDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about-us`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact-us`}
            component={Contact}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
