import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class ServiceSlider extends Component {
  render() {
    const params = {
      slidesPerView: 3,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      spaceBetween: 30,

      autoplay: {
        delay: 3000
      },
      // Responsive breakpoints
      breakpoints: {
        1499: {
          slidesPerView: 3
        },

        991: {
          slidesPerView: 2
        },
        767: {
          slidesPerView: 1
        },
        575: {
          slidesPerView: 1
        },
        320: {
          slidesPerView: 1
        }
      }
    };
    let data = [
      {
        img: 'service-tab-01.jpg',
        // iconName: 'flaticon-002-welding',
        serviceTitle: 'À Medida',
        serviceExcerpt:
          'Projetamos e fabricamos sob medida. Fabricamos mais de 85% dos produtos e equipamentos comercializados',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'service-tab-02.jpg',
        iconName: 'flaticon-004-walkie-talkie',
        serviceTitle: 'Aspiração',
        serviceExcerpt:
          'Oferecemos muitos dispositivos adaptados a diferentes setores e indústrias. Sabemos como adaptar o nosso trabalho às suas necessidades.',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'service-tab-03.jpg',
        // iconName: 'flaticon-015-cart',
        serviceTitle: 'Acabamento',
        serviceExcerpt:
          'Dispomos de mesas de lixagem auto-aspiradas, zonas de lixagem completas para diversos materiais e cabines com sistema de isolamento, pressurização e controle de temperatura',
        serviceUrl: 'service-details-left-sidebar'
      },
      {
        img: 'service-tab-04.jpg',
        // iconName: 'flaticon-010-tank-1',
        serviceTitle: 'Filtragem e Tratamento',
        serviceExcerpt:
          'Temos várias soluções para a filtragem e tratamento de odores, vapores e outros tipos de solventes poluentes',
        serviceUrl: 'service-details-left-sidebar'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide" key={i}>
          <div className="service-grid-item">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <img
                  src={`assets/img/services/${val.img}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
              {/* <div className="icon">
                <i className={val.iconName} />
              </div> */}
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                {/* <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}> */}
                <a>{val.serviceTitle}</a>
              </h3>
              <p className="subtitle">{val.serviceExcerpt}</p>
              {/* <a
                href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}
                className="see-more-link"
              >
                SEE MORE
              </a> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  service grid slider area ====================*/}
        <div className="service-grid-slider-area section-space--inner--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <h2 className="section-title section-space--bottom--50">
                    Os Nossos Serviços
                    {/* <span className="title-icon" /> */}
                  </h2>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-slider">
                  <Swiper {...params}>{DataList}</Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of service grid slider area  ====================*/}
      </div>
    );
  }
}

export default ServiceSlider;
