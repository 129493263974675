import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class Funfact extends Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({
        didViewCountUp: true
      });
    }
  };

  render() {
    let data = [
      {
        img: 'funfact-success.png',
        countNum: 31,
        countTitle: 'Anos'
      },
      {
        img: 'map.png',
        countNum: 9,
        countTitle: 'Países'
      },
      {
        img: 'PMELider2020_v2.png',
        countNum: 2020,
        countTitle: ' PME Líder',
        countTitle2: 'pelo 6º ano consecutivo'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div
          className="single-fact col-md-4 col-12 section-space--bottom--30"
          key={i}
        >
          <img src={`assets/img/icons/${val.img}`} alt="" />
          <h1 className="counter">
            <VisibilitySensor
              onChange={this.onVisibilityChange}
              offset={{
                top: 10
              }}
              delayedCall
            >
              <CountUp
                duration={1}
                end={this.state.didViewCountUp ? val.countNum : 0}
                
              />
            </VisibilitySensor>
          </h1>
          <h4> {val.countTitle} </h4>
          <h4>{val.countTitle2 ?` ${val.countTitle2}`: null} </h4> 
        </div>
      );
    });

    return (
      <div>
        <div className="funfact-section section-space--inner--100 funfact-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="fun-fact-wrapper">
                  <div className="row"> {DataList} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Funfact;
