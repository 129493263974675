import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector('nav');
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById('scroll-top').classList.add('show')
      : document.getElementById('scroll-top').classList.remove('show');
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className="footer-area dark-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--100">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-12">
                      {/* footer intro wrapper */}
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo">
                          <a href={`${process.env.PUBLIC_URL}/home-one`}>
                            <img
                              src="assets/img/logo/ventasel_logo_white.png"
                              className="img-fluid"
                              alt=""
                              style={{ maxWidth: '50%' }}
                            />
                          </a>
                        </div>
                        <div className="footer-desc">
                          Sistemas De Recuperação De Energia{' '}
                          <span>Aspiração, Purificação, Despoeiramento</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title">Links Úteis</h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>Início</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/projects`}>
                              Projectos
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/contact-us`}>
                              Contacte-nos
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/contact-us`}>
                              Política de privacidade
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
                            >
                              Noticias
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>

                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget mb-0">
                        <a href={`${process.env.PUBLIC_URL}/contact-us`}>
                          <h4 className="footer-widget__title">Contacte-nos</h4>
                        </a>
                        <div className="footer-widget__content">
                          <p className="address">
                            Zona Industrial de Sam, Pavilhão 11/12/13
                            <span>Ribeirao 4779-098</span>
                          </p>

                          <ul className="contact-details">
                            <li>
                              <span>T:</span>+351 252 491 220
                            </li>
                            <li>
                              <span>E:</span>geral@ventasel.com
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              Copyright © 2019. All right reserved
            </div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
        {/*====================  End of scroll top  ====================*/}
      </div>
    );
  }
}

export default Footer;
