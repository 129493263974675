import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PhotoGallery from './components/PhotoGallery';
import ProjectDetailsTitle from './components/ProjectDetailsTitle';
import ProductImages from './components/ProductDetailHeader';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '',
      data: {},
      isLoaded: false
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const tab = params.detail;
    let data = ProductImages(tab);

    this.setState({
      tab: tab,
      isLoaded: true,
      data: data
    });
  }

  // componentDidUpdate(prevProps) {
  //     console.log(this.state)
  //     console.log(prevProps)
  //     // Typical usage (don't forget to compare props):
  //     // if (this.props.userID !== prevProps.userID) {
  //     //   this.fetchData(this.props.userID);
  //     // }
  //   }

  render() {
    const { group, homeImage, title, text, subText } = this.state.data;
    const { isLoaded, tab } = this.state;

    console.log(tab);

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/*====================  breadcrumb area ====================*/}
        {isLoaded ? (
          <ProjectDetailsTitle
            title={title}
            text={text}
            group={group}
            homeImage={homeImage}
          ></ProjectDetailsTitle>
        ) : null}

        <div className="page-wrapper section-space--inner--120">
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <img
                    src={`/assets/img/projects/${homeImage}`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>{title}</h2>

                    <p>{text}</p>
                    {subText ? <p>{subText}</p> : null}
                  </div>
                </div>
              </div>

              <div className="col-12">
                {this.state.isLoaded ? <PhotoGallery id={tab} /> : null}
              </div>
            </div>
          </div>
        </div>
        {/*Projects section end*/}

        <BrandLogoSlider background="grey-bg" />

        <Footer />

        <MobileMenu />
      </div>
    );
  }
}

export default Projects;
