import React, { Component } from 'react';
import { LightgalleryProvider } from 'react-lightgallery';
import ProjectsPhotoItem from './ProjectsPhotoItem';
import PhotoItem from './PhotoItem';

class PhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      PROJECT_IMAGES: [],
      isLoaded: false
    };
  }

  componentDidMount() {
    const group = this.props.id;
    let images = [];

    if (group === 'baterias') {
      images = [
        'bateria-01',
        'bateria-02',
        'bateria-03',
        'bateria-04',
        'bateria-05',
        'bateria-06',
        'bateria-07',
        'bateria-08',
        'bateria-09',
        'bateria-10',
        'bateria-11',
        'bateria-12',
        'bateria-13',
        'bateria-14',
        'bateria-15',
        'bateria-16',
        'bateria-17',
        'bateria-18',
        'bateria-19'
      ];
    } else if (group === 'silos') {
      images = [
        'silo-01',
        'silo-02',
        'silo-03',
        'silo-04',
        'silo-05',
        'silo-06',
        'silo-07',
        'silo-08',
        'silo-09',
        'silo-10',
        'silo-11',
        'silo-12'
      ];
    } else if (group === 'lixagem') {
      images = [
        'lixagem-01',
        'lixagem-02',
        'lixagem-03',
        'lixagem-04',
        'lixagem-05',
        'lixagem-07',
        'lixagem-08',
        'lixagem-09',
        'lixagem-10',
        'lixagem-11',
        'lixagem-12',
        'lixagem-13',
        'lixagem-14'
      ];
    } else if (group === 'cabines') {
      images = [
        'cabine-01',
        'cabine-02',
        'cabine-03',
        'cabine-04',
        'cabine-05',
        'cabine-06',
        'cabine-07',
        'cabine-08',
        'cabine-09',
        'cabine-10',
        'cabine-11'
      ];
    } else if (group === 'ventiladores') {
      images = ['vent-01', 'vent-02', 'vent-03'];
    } else if (group === 'caldeiras') {
      images = [
        'caldeira-01',
        'caldeira-02',
        'caldeira-03',
        'caldeira-04',
        'caldeira-05',
        'caldeira-06',
        'caldeira-07',
        'caldeira-08'
      ];
    } else if (group === 'calcado') {
      images = ['shoes-01', 'shoes-02', 'shoes-03', 'shoes-04'];
    } else if (group === 'metal') {
      images = ['metal-01', 'metal-02'];
    } else if (group === 'project1') {
      images = [
        'project1-01',
        'project1-02',
        'project1-03',
        'project1-04',
        'project1-05',
        'project1-06',
        'project1-07',
        'project1-08',
        'project1-09'
      ];
    } else if (group === 'project2') {
      images = [
        'project2-01',
        'project2-02',
        'project2-03',
        'project2-04',
        'project2-05',
        'project2-06',
        'project2-06',
        'project2-07',
        'project2-08',
        'project2-09',
        'project2-10'
      ];
    } else if (group === 'project3') {
      images = [
        'project3-01',
        'project3-02',
        'project3-03',
        'project3-04',
        'project3-05',
        'project3-06',
        'project3-07',
        'project3-08'
      ];
    }

    this.setState({
      id: group,
      PROJECT_IMAGES: images,
      isLoaded: true
    });
  }

  render() {
    const { PROJECT_IMAGES, isLoaded, id } = this.state;

    return (
      <>
        <LightgalleryProvider>
          <div className="row row-5">
            {PROJECT_IMAGES.map((p, idx) => (
              <PhotoItem key={idx} image={p} group={id} />
            ))}
          </div>
        </LightgalleryProvider>
      </>
    );
  }
}

export default PhotoGallery;
