import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';

class AboutHome extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div>
        {/*====================  video cta area ====================*/}
        <div className="video-cta-area section-space--inner--120">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="video-cta-content">
                  <h4 className="video-cta-content__small-title">Sobre nós</h4>
                  <h3 className="video-cta-content__title">Ventasel, Lda</h3>
                  <p className="video-cta-content__text">
                    Uma empresa especializada no desenvolvimento e construção de
                    sistemas de aspiração, despoeiramento e purificação.
                  </p>
                  <p className="video-cta-content__text">
                    Com mais de 30 anos de experiência e um vasto conhecimento
                    nesta área, trabalhamos para qualquer setor industrial,
                    desenvolvendo soluções à medida do cliente.
                  </p>
                  <a href={`/about-us`} className="ht-btn ht-btn--round">
                    SOBRE NÓS
                  </a>
                </div>
              </div>
              <div className="col-lg-5  col-md-6">
                <div className="cta-video-image">
                  {/* <div className="video-popup">
                    <ModalVideo
                      channel="youtube"
                      isOpen={this.state.isOpen}
                      videoId="XAPz7q5aKPY"
                      onClose={() => this.setState({ isOpen: false })}
                    />
                    <button onClick={this.openModal}>
                      <div className="cta-video-image__image">
                        <img
                          src="assets/img/about/about-video.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="cta-video-image__icon">
                        <i className="ion-ios-play" />
                      </div>
                    </button>
                  </div> */}
                  
                 <iframe  src="https://www.youtube.com/embed/XAPz7q5aKPY?autoplay=1&loop=1" 
                     width="100%" height="315" frameborder="0" allow="autoplay" frameborder="0" loop="1" allowfullscreen="1"> 
                </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of video cta area  ====================*/}
      </div>
    );
  }
}

export default AboutHome;
