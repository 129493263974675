import React, { Component } from 'react';
import NavBar from './components/NavBar';
import HomePageSlider from './components/HomePageSlider';
import ServiceSlider from './components/ServiceSlider';
import AboutHome from './components/AboutHome';
import Funfact from './components/Funfact';
import ProjectImageGallery from './components/ProjectImageGallery';
import BlogGrid from './components/BlogGrid';
import Footer from './components/Footer';
import BrandLogoSlider from './components/BrandLogoSlider';
import DocumentTitle from 'react-document-title';
import HomePageVideo from './components/HomePageVideo';
import ProductSlider from './components/ProductSlider';

class HomePage extends Component {
  render() {
    return (
      <>
        <NavBar />

        <HomePageVideo />
        {/* <HomePageSlider /> */}

        <ProductSlider />
        {/* <ServiceSlider></ServiceSlider>  */}

        <Funfact />

        <AboutHome />

        <ProjectImageGallery />

        {/* 
        
        <BrandLogoSlider />
        
        <BlogGrid />
        
        */}

        <Footer />
      </>
    );
  }
}

export default HomePage;
