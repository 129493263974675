const ProductDetailHeader = group => {
  if (group === 'baterias') {
    return {
      group: 'Baterias',
      homeImage: 'baterias/bateriaHome.png',
      title: 'Extração e Filtragem',
      text:
        'As baterias LAC ( Limpeza a Ar Comprimido) são fabricadas a partir de painéis ​​modulares utilizados para filtrar e tratar grandes quantidades de resíduos. São pensadas à medida do cliente e as mangas filtrantes adaptadas ao tipo de resíduo a ser filtrado.',
      subText: 'Fácil manutenção , alta eficiência e baixa perda de carga.'
    };
  } else if (group === 'silos') {
    return {
      group: 'Silos',
      homeImage: 'silos/siloHome.png',
      title: 'Armazenamento e Extração',
      text:
        'Ideal para o armazenamento e filtragem dos mais diversos tipos de resíduos industriais.',
      subText: 'Com extração automática e capacidade ajustável '
    };
  } else if (group === 'lixagem') {
    return {
      group: 'Zonas de Lixagem',
      homeImage: 'lixagem/lixagemHome.png',
      title: 'Acabamento e Extração',
      text:
        'Mesas de lixagem auto-aspiradas, zonas de lixagem completas e filtros específicos para diversos materiais.',
      subText: 'Fabricamos zonas de lixagem por medida.'
    };
  } else if (group === 'cabines') {
    return {
      group: 'Cabines',
      homeImage: 'cabines/cabineHome.png',
      title: 'Acabamento e Controlo',
      text:
        'Cabines com sistema de isolamento, pressurização, controlo de temperatura e cortinas de água projetadas e fabricadas de acordo com as e normas técnicas de preservação do meio ambiente.',
      subText:
        'Um melhor acabamento e proteção da saúde dos seus colaboradores.'
    };
  } else if (group === 'ventiladores') {
    return {
      group: 'Ventiladores',
      homeImage: 'ventiladores/ventHome.png',
      title: 'Extração',
      text:
        'O ar desempenha um papel muito importante nos processos industriais. Dispomos de uma variada gama de ventiladores para fornecer, extrair e transportar ar e outros tipos de materiais.'
    };
  } else if (group === 'calcado') {
    return {
      group: 'Indústria do Calçado',
      homeImage: 'calcado/calcadoHome.png',
      title: 'Soluções Indústriais',
      text:
        'Com muitos anos de experiência neste setor industrial, temos uma diversa gama de produtos e equipamentos especialmente adpatados para a indústria do calçado.'
    };
  } else if (group === 'metal') {
    return {
      group: 'Indústria da Metalomecânica',
      homeImage: 'metal/metalHome.png',
      title: 'Soluções Indústriais',
      text:
        'A solução mais comum para aspirar os fumos de soldadura são os braços extratores. Dispomos de várias soluções de braços extratores que oferecem total flexibilidade.'
    };
  } else if (group === 'arr') {
    return {
      group: 'Aparelhos Adiabaticos',
      homeImage: 'arr/arrHome.png',
      title: 'Soluções Indústriais',
      text:
        'Os aparelhos adiabáticos fazem passar o ar do exterior por uns filtros com circulação de água. Através do processo evaporativo, o ar arrefece e é encaminhado para o interior através de um ventilador.',
      subText: 'Ar fresco 100% filtrado e renovado'
    };
  } else if (group === 'caldeiras') {
    return {
      group: 'Caldeiras',
      homeImage: 'caldeiras/caldeiraHome.png',
      title: 'Controlo de Temperatura',
      text: 'Diesel | Lenha | Mistas | Biomassa |',
      subText: 'Caleiras para aquecimento e controlo de temperatura '
    };
  } else if (group === 'project-1') {
    return {
      group: 'Projecto ',
      homeImage: 'caldeiras/caldeiraHome.png',
      title: 'Controlo de Temperatura'
      // text:
      //   'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem veritatis quo et ullam, ducimus itaque earum dolorem? Consectetur, et, aut. A, corporis officia eius dicta explicabo saepe nesciunt, mollitia minima, atque maiores optio cum. Atque amet unde impedit voluptate cumque distinctio minima, aspernatur nemo! Expedita in, numquam blanditiis ullam rem!'
    };
  }
};

export default ProductDetailHeader;
