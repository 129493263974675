import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import { Suspense } from 'react';
import ProductImages from './components/ProductDetailHeader';

const VideoSlider = React.lazy(() => import('./components/VideoSlider'));

class Equipments extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let data = [
      {
        pageLink: 'project-details/baterias',
        img: 'project-1.png',
        projectTitle: 'Baterias',
        projectSubtitle: 'Extração e Filtragem'
      },
      {
        pageLink: 'project-details/silos',
        img: 'project-2.png',
        projectTitle: 'Silos',
        projectSubtitle: 'Armazenamento e Extração'
      },
      {
        pageLink: 'project-details/lixagem',
        img: 'project-3.png',
        projectTitle: 'Zonas de Lixagem',
        projectSubtitle: 'Soluções Indústriais'
      },
      {
        pageLink: 'project-details/cabines',
        img: 'project-4.png',
        projectTitle: 'Cabines',
        projectSubtitle: 'Soluções Indústriais'
      },
      {
        pageLink: 'project-details/ventiladores',
        img: 'project-5.png',
        projectTitle: 'Ventiladores',
        projectSubtitle: 'Extração'
      },
      {
        pageLink: 'project-details/calcado',
        img: 'project-6.png',
        projectTitle: 'Indústria do Calçado',
        projectSubtitle: 'Soluções Indústriais'
      },
      {
        pageLink: 'project-details/metal',
        img: 'project-7.png',
        projectTitle: 'Metalomecânica',
        projectSubtitle: 'Soluções Indústriais'
      },
      {
        pageLink: 'project-details/arr',
        img: 'project-8.png',
        projectTitle: 'Controlo de Temperatura',
        projectSubtitle: 'Humidificadores e APs'
      },
      {
        pageLink: 'project-details/caldeiras',
        img: 'project-9.png',
        projectTitle: 'Caldeiras',
        projectSubtitle: 'Controlo de Temperatura'
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <a href={`/${val.pageLink}`}>
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`/${val.pageLink}`}>{val.projectTitle}</a>
              </h3>
              <p className="subtitle">{val.projectSubtitle}</p>
              <a href={`/${val.pageLink}`} className="see-more-link">
                VER MAIS
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Equipamentos</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Início</a>
                    </li>
                    <li>Equipamentos</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}
        <Suspense
          fallback={
            <div className="row justify-content-center">
              <img src="/assets/img/logo/loading.gif" />
              <h4>A carregar galeria vídeo...</h4>
            </div>
          }
        >
          <VideoSlider />
        </Suspense>

        {/*====================  project page content ====================*/}

        <div className="page-wrapper">
          <div className="section-title-area text-center">
            <h2 className="section-title section-space--bottom--50">
              Os Nossos Produtos
            </h2>
          </div>
          {/*Projects section start*/}
          <div className="project-section section-space--bottom--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
              {/* <div className='row section-space--top--60'>
                <div className='col'>
                  <ul className='page-pagination'>
                    <li>
                      <a href='/'>
                        <i className='fa fa-angle-left' /> Prev
                      </a>
                    </li>
                    <li className='active'>
                      <a href='/'>01</a>
                    </li>
                    <li>
                      <a href='/'>02</a>
                    </li>
                    <li>
                      <a href='/'>03</a>
                    </li>
                    <li>
                      <a href='/'>
                        <i className='fa fa-angle-right' /> Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project page content  ====================*/}

        {/* Brand logo */}
        {/* <BrandLogoSlider background="grey-bg" /> */}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Equipments;
