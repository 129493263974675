import React, { Component } from 'react';
import { LightgalleryItem } from 'react-lightgallery';

export default class ProjectsPhotoItem extends Component {
  render() {
    const { group, image } = this.props;

    const path = `/assets/img/projects/${group}/${image}`;
    console.log(group, image);

    return (
      <div className="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10">
        <LightgalleryItem group={group} src={`${path}.jpg`}>
          <button className="gallery-item single-gallery-thumb">
            <img src={`${path}_tn.jpg`} className="img-fluid" alt="" />
            <span className="plus" />
          </button>
        </LightgalleryItem>
      </div>
    );
  }
}
