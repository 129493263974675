import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProjectImageGallery extends Component {
  render() {
    let data = [
      {
        pageLink: 'projects',
        className: 'col-md-6',
        img_src: 'assets/img/projects/homeprojects/home-proj-01.jpg',
        img: 'project-1.png',
        projectTitle: 'Projeto 1',
        projectSubtitle: 'Sistema Completo'
      },
      {
        pageLink: 'projects',
        img: 'project-2.png',
        img_src: 'assets/img/projects/homeprojects/home-proj-02.jpg',
        className: 'col-md-3 col-mobile-6',
        projectTitle: 'Projeto 2',
        projectSubtitle: 'Sistema Completo'
      },
      {
        pageLink: 'projects',
        img: 'project-3.png',
        img_src: 'assets/img/projects/homeprojects/home-proj-03.jpg',
        className: 'col-md-3 col-mobile-6',
        projectTitle: 'Projeto 3',
        projectSubtitle: 'Sistema Completo'
      },
      {
        pageLink: 'projects',
        className: 'col-md-3 col-mobile-6',
        img_src: 'assets/img/projects/homeprojects/home-proj-04.jpg',
        img: 'project-1.png',
        projectTitle: 'Projeto 1',
        projectSubtitle: 'Sistema Completo'
      },
      {
        pageLink: 'projects',
        img: 'project-1.png',
        img_src: 'assets/img/projects/homeprojects/home-proj-05.jpg',
        className: 'col-md-3 col-mobile-6',
        projectTitle: 'Projeto 2',
        projectSubtitle: 'Sistema Completo'
      },
      {
        pageLink: 'projects',
        img: 'project-1.png',
        className: 'col-md-6',
        img_src: 'assets/img/projects/homeprojects/home-proj-06.jpg',
        projectTitle: 'Projeto 3',
        projectSubtitle: 'Sistema Completo'
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div className={val.className}>
          <div className="single-gallery-project">
            <div className="single-gallery-project__image">
              <img src={val.img_src} className="img-fluid" alt="" />
            </div>
            <div className="single-gallery-project__content">
              <Link to={val.pageLink}>VER MAIS</Link>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  project gallery area ====================*/}
        <div className="project-gallery-area section-space--inner--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title-area text-center section-space--bottom--50">
                  <h2 className="section-title">Os Nossos Projetos</h2>
                  <p className="section-subtitle">
                    Porque temos orgulho em tudo o que fazemos
                    {/* <span className="title-icon" /> */}
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="project-gallery-wrapper">
                  <div className="row">
                    {Datalist}
                    {/* <div className="col-md-6">
                      <div className="single-gallery-project">
                        <div className="single-gallery-project__image">
                          <img
                            src="assets/img/projects/homeprojects/home-proj-01.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-gallery-project__content">
                          <Link to="/projects">VER MAIS</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-mobile-6">
                      <div className="single-gallery-project">
                        <div className="single-gallery-project__image">
                          <img
                            src="assets/img/projects/homeprojects/home-proj-02.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-gallery-project__content">
                          <Link to="/projects">VER MAIS</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-mobile-6">
                      <div className="single-gallery-project">
                        <div className="single-gallery-project__image">
                          <img
                            src="assets/img/projects/homeprojects/home-proj-03.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-gallery-project__content">
                          <Link to="/projects">VER MAIS</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-mobile-6">
                      <div className="single-gallery-project">
                        <div className="single-gallery-project__image">
                          <img
                            src="assets/img/projects/homeprojects/home-proj-04.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-gallery-project__content">
                          <Link to="/projects">VER MAIS</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-mobile-6">
                      <div className="single-gallery-project">
                        <div className="single-gallery-project__image">
                          <img
                            src="assets/img/projects/homeprojects/home-proj-05.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-gallery-project__content">
                          <Link to="/projects">VER MAIS</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-gallery-project">
                        <div className="single-gallery-project__image">
                          <img
                            src="assets/img/projects/homeprojects/home-proj-06.jpg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-gallery-project__content">
                          <Link to="/projects">VER MAIS</Link>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div
                    className="row justify-content-center"
                    style={{ padding: '100px' }}
                  >
                    <img
                      src="assets/img/backgrounds/vtsDraw.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>

                  <div className="row justify-content-center">
                    <div className="row justify-content-center">
                      <a href="/projects" class="ht-btn ht-btn--round">
                        VER PROJETOS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*====================  End of project gallery area  ====================*/}
        </div>
      </div>
    );
  }
}

export default ProjectImageGallery;
