import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import axios from 'axios';
import swal from 'sweetalert';
import GoogleApiWrapper from './components/MapContainer';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '',
      message: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  clearState = () => {
    this.setState({
      name: '',
      email: '',
      company: '',
      message: '',
      phone: ''
    });
  };

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);

    const { name, phone, email, company, message } = this.state;

    const data = {
      name,
      email,
      phone,
      company,
      message
    };
    const form = await axios
      .post('/api/emailForm', data)
      .then(() => this.clearState())
      .then(
        swal({
          title: 'Mesagem enviada com sucesso!',
          text: 'Entraremos em contacto o mais rápido possível',
          icon: 'success'
        })
      )
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Fale Conosco</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Início</a>
                    </li>
                    <li>Contacto</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Contact section start*/}
          <div className="conact-section">
            <div className="container">
              <div className="row section-space--bottom--50">
                <div className="col">
                  <div className="contact-map">
                    <GoogleApiWrapper />

                    {/* <iframe
                      title="google-map"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d392436.93004030554!2d-105.13838587646829!3d39.7265847007123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1558429398879!5m2!1sen!2sbd"
                      allowFullScreen
                    ></iframe> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="contact-information">
                    <h3>Informações de Contacto</h3>
                    <ul>
                      <li>
                        <span className="icon">
                          <i className="ion-android-map" />
                        </span>
                        <span className="text">
                          <span>
                            Zona Industrial de Sam
                            <span>Pavilhão 11/12 e 13</span>
                          </span>
                          4764-908 Ribeirão, Vila Nova de Famalicão
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-telephone-outline" />
                        </span>
                        <span className="text">
                          <a href="tel:1234567890">(+351) 252 491 220</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="mailto:test@ventasel.com">
                            geral@ventasel.com
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="contact-form">
                    <h3>Deixe A Sua Mensagem</h3>
                    <form>
                      <div className="row row-10">
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            name="name"
                            type="text"
                            placeholder="Nome"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            name="email"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            value={this.state.company}
                            onChange={this.handleInputChange}
                            name="company"
                            type="text"
                            placeholder="Empresa"
                          />
                        </div>
                        <div className="col-md-6 col-12 section-space--bottom--20">
                          <input
                            value={this.state.phone}
                            onChange={this.handleInputChange}
                            name="phone"
                            type="text"
                            placeholder="Contacto"
                          />
                        </div>
                        <div className="col-12">
                          <textarea
                            value={this.state.message}
                            onChange={this.handleInputChange}
                            name="message"
                            placeholder="Mensagem"
                          />
                        </div>
                        <div className="col-12">
                          <button type="submit" onClick={this.handleSubmit}>
                            Enviar Mensagem
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Contact;
