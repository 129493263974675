import React, { Component } from 'react';
import Swiper from 'react-id-swiper';


class HomePageSlider extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      effect: 'fade',
      navigation: {
        nextEl: '.ht-swiper-button-next',
        prevEl: '.ht-swiper-button-prev'
      },
      renderPrevButton: () => (
        <div className="ht-swiper-button-prev ht-swiper-button-nav d-none d-xl-block">
          <i className="ion-ios-arrow-left" />
        </div>
      ),
      renderNextButton: () => (
        <div className="ht-swiper-button-next ht-swiper-button-nav d-none d-xl-block">
          <i className="ion-ios-arrow-forward" />
        </div>
      ),
      autoplay: {
        delay: 5000
      }
    };

    let data = [
      {
        bgImg: 'home.jpg',
        sliderTitle: 'Uma Referência Nacional',
        sliderSubtitle: 'Com mais de 30 anos de experiência na indústria!',
        btnLink: 'contact-us'
      },
      {
        bgImg: 'home.jpg',
        sliderTitle: 'Soluções, Produtos e Equipamentos',
        sliderSubtitle:
          'Destingimo-nos pela fiabilidade dos materiais utilizados e pela qualidade e certificação dos produtos',
        btnLink: 'contact-us'
      },
      {
        bgImg: 'home.jpg',
        sliderTitle: 'Em Prol do Ambiente',
        sliderSubtitle:
          'Recuperação de Energia, Purificação, Aspiração, Serralharia',
        btnLink: 'contact-us'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="swiper-slide" key={i}>
          <div
            className="hero-slider__single-item"
            style={{ backgroundImage: `url(assets/img/slider/${val.bgImg})` }}
          >
            <div className="hero-slider__content-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="hero-slider__content">
                      <h2 className="hero-slider__title">{val.sliderTitle}</h2>
                      <p className="hero-slider__text">{val.sliderSubtitle}</p>
                      <a
                        className="hero-slider__btn"
                        href={`${process.env.PUBLIC_URL}/${val.btnLink}`}
                      >
                        FALE CONOSCO
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  hero slider area ====================*/}
        <div className="hero-alider-area">

          
{/*
        <iframe width="720" height="315" frameborder="0"
src="https://www.youtube.com/embed/x3FnwHIEEk4?autoplay=1&loop=1" allow="autoplay" frameborder="0" loop="1"> 
</iframe> 
       */}
           <Swiper {...params}>{DataList}</Swiper> 
        </div>
      {/*  ====================  End of hero slider area  ====================*/}
      </div>
    );
  }
}

export default HomePageSlider;
