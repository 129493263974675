import React, { Component } from 'react';

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

// ...
const mapStyles = {
  width: '100%',
  height: '100%'
};

export class WorldMapContainer extends React.Component {
  state = {
    showingInfoWindow: false, //Hides or the shows the infoWindow
    activeMarker: {}, //Shows the active marker upon click
    selectedPlace: {} //Shows the infoWindow to the selected place upon a marker
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={3}
        style={mapStyles}
        initialCenter={{ lat: 41.352766, lng: -8.581655999999999 }}
      >
        <Marker
          title="Portugal"
          onClick={this.onMarkerClick}
          position={{ lat: 41.352766, lng: -8.581655999999999 }}
        />
        <Marker
          title="Austrália, lda"
          onClick={this.onMarkerClick}
          position={{ lat: -26.955386, lng: 133.717276 }}
        />
        <Marker
          title="Tailândia"
          onClick={this.onMarkerClick}
          position={{ lat: 13.693874, lng: 100.475173 }}
        />
        <Marker
          title="Tailândia"
          onClick={this.onMarkerClick}
          position={{ lat: -12.996356, lng: 18.043118 }}
        />
        <Marker
          title="Paraguai"
          onClick={this.onMarkerClick}
          position={{ lat: -25.275274, lng: -57.562212 }}
        />
        <Marker
          title="Moçambique"
          onClick={this.onMarkerClick}
          position={{ lat: -16.10867, lng: 38.181958 }}
        />
        <Marker
          title="Espanha"
          onClick={this.onMarkerClick}
          position={{ lat: 40.416775, lng: -3.70379 }}
        />
        <Marker
          title="México"
          onClick={this.onMarkerClick}
          position={{ lat: 19.432608, lng: -99.133209 }}
        />
        <Marker
          title="Açores"
          onClick={this.onMarkerClick}
          position={{ lat: 38.305542, lng: -30.384108 }}
        />
        <Marker
          title="Madeira"
          onClick={this.onMarkerClick}
          position={{ lat: 32.3716651, lng: -16.2749989 }}
        />
        <Marker
          title="Roménia"
          onClick={this.onMarkerClick}
          position={{ lat: 44.439663, lng: 26.096306 }}
        />
        {/* <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>Ventasel</h4>
            <br />
            <p>Zona Industrial de Sam</p>
            <p>Pavilhão 11/12 e 13</p>
            <p>4764-908 Ribeirão, Vila Nova de Famalicão</p>
          </div>
        </InfoWindow> */}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBG4lAYvX6WUepOMQ9qRlV-Nn8fYS6ghFc'
})(WorldMapContainer);
