import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';

class Projects extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let data = [
      {
        pageLink: 'full-projects/project-1',
        img: '/project1/project1.jpg',
        projectTitle: 'Açores',
        projectSubtitle:
          'Sistemas de despoeiramento, aspiração e ventilação nas ilhas açoreanas'
      },
      {
        pageLink: 'full-projects/project-2',
        img: '/project2/project2.jpg',
        projectTitle: 'Sistema despoeiramento',
        projectSubtitle:
          'Sistema de despoeiramento completo para a indústria da madeira'
      },
      {
        pageLink: 'full-projects/project-3',
        img: '/project3/project3.jpg',
        projectTitle: 'Oliveira do Hospital',
        projectSubtitle: 'Sistema Completo'
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <a href={`/${val.pageLink}`}>
                  <img
                    src={`assets/img/fullProjects/${val.img}`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`/${val.pageLink}`}>{val.projectTitle}</a>
              </h3>
              <p className="subtitle">{val.projectSubtitle}</p>
              <a href={`/${val.pageLink}`} className="see-more-link">
                VER MAIS
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Projetos</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Início</a>
                    </li>
                    <li>Projetos</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
              {/* <div className='row section-space--top--60'>
                <div className='col'>
                  <ul className='page-pagination'>
                    <li>
                      <a href='/'>
                        <i className='fa fa-angle-left' /> Prev
                      </a>
                    </li>
                    <li className='active'>
                      <a href='/'>01</a>
                    </li>
                    <li>
                      <a href='/'>02</a>
                    </li>
                    <li>
                      <a href='/'>03</a>
                    </li>
                    <li>
                      <a href='/'>
                        <i className='fa fa-angle-right' /> Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project page content  ====================*/}

        {/* Brand logo */}
        {/* <BrandLogoSlider background="grey-bg" /> */}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Projects;
