import React, { Component } from 'react';
import banner from './vid/websitebanner.mp4'


class HomePageVideo extends Component {
  render() {   

    return (
      <div>
      <div className="row">
        <div className="col-lg-12">
        {/*====================  hero slider area ====================
        
          <div className="hero-alider-area">*/}
      
          <video
          autoplay='1'
          loop
          muted 
          style={{
            position:'relative',
            width: '100%',
            left: '50%',
            top: '50%',
            height: '100%',
            objectFit:'cover',
            transform: 'translate(-50%, -50%)',
            zIndex: '-1'
          }}>
            <source src={banner} type="video/mp4"></source>
            
          </video>
          
{/*

           <Swiper {...params}>{DataList}</Swiper> */} 
       {/* ====================  End of hero slider area  ====================*/}
</div>
</div>
       
      </div>
    );
  }
}

export default HomePageVideo;
