import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video';
import WorldMapContainer from './components/WorldMapContainer';

class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Sobre Nós</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Início</a>
                    </li>
                    <li>Sobre Nós</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--120">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    {/* <img src="assets/img/about/about-video.jpg" alt="" />
                    <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="Ag-sOfXHdDY"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}

                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/XAPz7q5aKPY?autoplay=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      autoplay
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Bem-vindo à Ventasel</h3>
                    <h1>Mais de 30 anos de expriência indústrial! </h1>
                    {/* <h4>
                      Com vasto conhecimento nesta àrea, trabalhamos para
                      qualquer setor industrial, desenvolvendo soluções à medida
                      do cliente
                    </h4> */}
                    <p>
                      Uma empresa especializada no desenvolvimento e contrução
                      de sistemas de aspiração, despoieiramento e purificação.
                    </p>
                    <p>
                      Distinguimo-nos pela qualidade e fiabilidade dos materiais
                      utilizados, pela montagem qualificada e assistência
                      pós-venda. Tudo isto, executado em total acordo com as
                      últimas exigências e regulamentos de protecção ambiental.
                    </p>
                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Our Services
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Feature Icon */}
          <FeatureIcon background="grey-bg" />

          <div className="about-section section-space--inner--120">
            <div className="container">
              <div className="row justify-content-center">
                <h2 className="section-title section-space--bottom--50">
                  Onde pode econtrar o nosso trabalho ?
                </h2>
              </div>
              <div className="col-lg-12 col-12 order-3 order-lg-1">
                {/* <div className="row col-md-12 justify-content-center section-space--inner--40 section-space--bottom--40">
                  <img
                    src="/assets/img/icons/globe-47.gif"
                    style={{ width: '80px' }}
                  ></img>
                </div> */}
                <div className="row justify-content-center">
                  <p>
                    Sendo já referência no mercado nacional, fazemos sempre por
                    levar a marca Ventasel um passo mais á frente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row section-space--bottom--50">
            <div className="col">
              <div className="contact-map" style={{ height: '800px' }}>
                <WorldMapContainer />

                {/* <iframe
                      title="google-map"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d392436.93004030554!2d-105.13838587646829!3d39.7265847007123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1558429398879!5m2!1sen!2sbd"
                      allowFullScreen
                    ></iframe> */}
              </div>
            </div>
          </div>

          {/*About section start*/}
          <div className="about-section section-space--inner--120">
            <div className="container">
              <div className="about-wrapper row">
                <div className="col-sm-6 col-12 order-1 order-lg-2">
                  <div className="about-image about-image-1">
                    <img src="assets/img/about/about-01.jpg" alt="" />
                  </div>
                </div>
                <div className="col-sm-6 col-12 order-2 order-lg-3">
                  <div className="about-image about-image-2">
                    <img src="assets/img/about/about-02.jpg" alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-1">
                  <div className="about-content about-content-1">
                    <h1>
                      <span>30</span>Anos de Experiência
                    </h1>
                    <p>
                      Com o <span>Know-how</span> adquirido ao longo de todos
                      estes anos somos capazes de desenvolver soluções
                      específicas para todo o tipo projetos
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 order-4">
                  <div className="about-content about-content-2">
                    <p>
                      Assim, na Ventasel Lda, trabalhamos para qualquer setor
                      indústrial e desenvolvemos soluções à medida, baseadas no
                      cliente e nas suas necessidades reais.
                    </p>
                    <a
                      href={`${process.env.PUBLIC_URL}/contact-us`}
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      FALE CONOSCO
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Team member 
                <TeamMemberGrid/>
                
               * Testimonial Slider 
                <TestimonialSlider/>
                */}
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default About;
